@import url(../../assets/variables.css);
@import url(../../assets/fonts.css);

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: stretch; /* Changed to stretch to allow children to control their alignment */
  background-color: #002d5c; /* Navy blue background */
  font-family: var(--font-oswald-regular);
  height: 80px; /* Ensure navbar has a defined height */
}

.navbar-left {
  display: flex;
  align-items: center;
  padding-left: 15px;
}

.logo {
  height: 50px;
  margin-right: 15px;
}

.navbar-center a {
  color: white;
  text-decoration: none;
  margin: 0 10px;
  font-weight: bold;
}

.navbar-right {
  display: flex;
  align-items: stretch; /* Allow children to stretch to fill height */
  height: 100%; /* Make sure it fills the navbar height */
}

.navbar-right a {
  color: white;
  text-decoration: none;
  margin: 0 auto;
}

.enquire-btn {
  background-color: #e30613; /* Red background for enquire button */
  display: flex;
  align-items: center; /* Center items vertically within the button */
  justify-content: center; /* Center items horizontally within the button */
  height: 100%; /* Make button fill the parent height */
  padding: 0 30px;
  color: white;
  text-decoration: none;
  border: none;
  font-weight: bold;
  border-radius: 0;
  font-family: var(--font-oswald-regular);
  cursor: pointer;
  transition: background-color 0.5s, color 0.5s;
}

.enquire-btn:hover {
  background-color: white;
  color: #e30613;
}

/* Media Queries */

/* Devices with a width of 1024px or less */
@media only screen and (max-width: 1024px) {
  .navbar {
    height: 70px; /* Slightly reduce navbar height */
  }

  .logo {
    height: 45px; /* Reduce logo size */
  }

  .navbar-center a {
    font-size: 0.95em; /* Slightly reduce font size */
  }

  .enquire-btn {
    padding: 0 25px; /* Adjust padding for smaller screens */
    font-size: 0.95em; /* Slightly reduce font size */
  }
}

/* Devices with a width of 768px or less */
@media only screen and (max-width: 768px) {
  .navbar {
    height: 60px; /* Further reduce navbar height */
  }

  .logo {
    height: 40px; /* Further reduce logo size */
  }

  .navbar-center a {
    font-size: 0.9em; /* Further reduce font size */
    margin: 0 8px; /* Reduce margin between links */
  }

  .enquire-btn {
    padding: 0 20px; /* Further adjust padding */
    font-size: 0.9em; /* Further reduce font size */
  }
}

/* Devices with a width of 480px or less */
@media only screen and (max-width: 480px) {
  .navbar {
    height: 64px; /* Reduce navbar height for small screens */
  }

  .logo {
    height: 35px; /* Further reduce logo size */
  }

  .navbar-center a {
    font-size: 0.85em; /* Further reduce font size */
    margin: 0 6px; /* Further reduce margin between links */
  }

  .enquire-btn {
    padding: 0 20px; /* Adjust padding for small screens */
    font-size: 0.85em; /* Further reduce font size */
    height: 100%; /* Ensure the button height fills the parent */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
}
