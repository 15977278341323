.thjonusta-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 20px;
    position: relative;
}

.thjonusta-header {
    position: absolute;
    top: 20px;
    left: 50px;
    z-index: 100;
}

.thjonusta-header h1 {
    color: #002d5c;
    font-size: 6rem;
    font-weight: 700;
    text-transform: uppercase;
}

.thjonusta-container {
    background-color: #e9e9e9;
    padding: 40px 80px;
    border-radius: 1px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%;
    max-width: 95%;
    margin-top: 4%;
}

.thjonusta-image {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    overflow: hidden; /* Ensures image can be cropped */
}

.thjonusta-image img {
    width: 100%; /* Make the image span the full width of the container */
    height: 600px; /* Set a fixed height to allow cropping */
    object-fit: cover; /* Ensure the image covers the container and crops the top/bottom */
    object-position: center; /* Center the image within the container */
}

.thjonusta-content {
    font-size: 1.4rem;
    line-height: 1.8;
    color: #002d5c;
    padding: 0 20px;
    max-width: 800px; /* Limit the width of the text */
    margin: 0 auto; /* Center the text horizontally */
    text-align: center;
    text-transform: none;
}

.thjonusta-book-btn {
    margin-top: 20px;
    display: inline-block;
    padding: 15px 30px; /* Moderate size padding for a large rectangular button */
    font-size: 1.2rem; /* Larger font size for visibility */
    color: #002dbc; /* Initial text color */
    background-color: white; /* Initial background color */
    border: 2px solid #002dbc; /* Blue border */
    border-radius: 5px; /* Slightly rounded corners */
    text-align: center; /* Center the text inside the button */
    text-decoration: none; /* Remove underline from the link */
    font-weight: 600; /* Make the text bold */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for hover effect */
    cursor: pointer; /* Pointer cursor for better UX */
}

.thjonusta-book-btn:hover {
    background-color: #002dbc; /* Fill button with the blue color */
    color: white; /* Change text to white */
}

@media (max-width: 768px) {
    .thjonusta-header h1 {
        font-size: 3.5rem;
    }

    .thjonusta-container {
        margin-top: 150px;
        padding: 20px;
    }

    .thjonusta-image img {
        max-width: 100%;
        height: 300px; /* Adjust height for smaller screens */
    }
    .thjonusta-content {
        max-width: 100%; /* Ensure text is responsive on smaller screens */
    }
}
