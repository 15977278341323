.form-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: 600;
    color: #333;
  }
  
  .form-group input,
  .form-group textarea,
  .form-group .date-picker {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .form-group textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  .submit-button {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }

  .book-btn{
    background-color: #002d5c;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 30px;
    color: white;
    text-decoration: none;
    border: none;
    font-weight: bold;
    border-radius: 0;
    font-family: var(--font-oswald-regular);
    cursor: pointer;
}

.book-btn span {
    position: relative;
    transition: transform 0.4s ease;
}

.book-btn span::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    background-color: #ff6347;
    left: 0;
    bottom: -2px;
    transition: width 0.5s ease;
}

.book-btn span:hover::after {
    width: 100%;
}

.book-btn span:hover {
    transform: scale(1.15);
}

  