/* General styles for the action bar */
.actionbar {
    display: flex;
    justify-content: space-around; /* Distribute items evenly */
    align-items: center; /* Center items vertically */
    background-color: #f5f2eb; /* White background */
    font-family: var(--font-oswald-regular); /* Use the Oswald font */
    height: 50px; /* Define the height */
    border-top: 1px solid #ccc; /* Optional: Add a top border for separation */
    border-bottom: 1px solid #ccc; /* Optional: Add a bottom border for separation */
}

/* Style for each link in the action bar */
.actionbar a {
    color: #002d5c; /* Navy blue color */
    text-decoration: none; /* Remove underline */
    margin: 0 10px; /* Space between links */
    font-weight: bold; /* Bold text */
}

.actionbar a:hover {
    text-decoration: underline; /* Add underline on hover */
}

/* Media Queries */

/* Devices with a width of 1024px or less */
@media only screen and (max-width: 1024px) {
    .actionbar {
        height: 45px; /* Slightly reduce height for smaller screens */
    }

    .actionbar a {
        font-size: 0.95em; /* Slightly reduce font size */
    }
}

/* Devices with a width of 768px or less */
@media only screen and (max-width: 768px) {
    .actionbar {
        height: 40px; /* Reduce height further for smaller tablets */
    }

    .actionbar a {
        font-size: 0.9em; /* Further reduce font size */
    }
}

/* Devices with a width of 480px or less */
@media only screen and (max-width: 480px) {
    .actionbar {
        height: 45px; /* Reduce height for small mobile screens */
    }

    .actionbar a {
        font-size: 0.85em; /* Further reduce font size */
    }
}
