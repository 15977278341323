/* Ensure margin and padding are reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.footer-container {
    display: flex;
    background-color: #002d5c;
    width: 100%;
    padding-top: 5%; /* Adjust padding as needed */
    font-family: var(--font-oswald-regular);
    padding-left: 20%;
    font-size: 2em;
    padding-bottom: 5%;
}

.footer-column-1 {
    color: white;
}

.footer-column-1 h3 {
    margin-top: 4%;
}

.footer-column-2 {
    color: white;
    margin-left: 15%;
    display: flex;
    flex-direction: column; /* Arrange items vertically */
    gap: 20px; /* Adds uniform spacing between items */
}

.opnunarTimi {
    display: inline-flex; /* Align items horizontally */
    align-items: center; /* Center text and icon vertically */
    text-decoration: none;
    color: white;
    transition: transform 0.3s ease;
    font-size: 1.5em;
    margin-top: 4%;
}

.opnunarTimi:hover {
    transform: scale(1.1);
}

.opnunarTimi .arrow-icon {
    width: 1em; /* Match icon size with text size */
    height: 1em; /* Match icon size with text size */
    margin-left: 0.5em; /* Initial space between text and icon */
    transition: transform 0.3s ease, margin-left 0.3s ease; /* Smooth hover effect */
}

.opnunarTimi:hover .arrow-icon {
    transform: translateX(0.1em); /* Move the icon further to the right */
    margin-left: 0.5em; /* Increase the space between text and icon on hover */
}

.footer-column-2 ol {
    list-style-type: none;
    display: flex; /* Keeps social media icons in a row */
    flex-direction: row; /* Ensure items are arranged horizontally */
}

.footer-column-2 ol li {
    margin-right: 5%;
}

#fbIcon,
#igIcon {
    width: 60px;  /* Set the desired width */
    height: 60px; /* Set the desired height */
    display: inline-block; /* Ensure they are displayed inline */
    transition: transform 0.3s ease; /* Add a smooth transition for hover effects */
}

#fbIcon:hover,
#igIcon:hover {
    transform: scale(1.25); /* Slightly enlarge the icon on hover */
}

/* Media Queries */

/* Devices with a width of 1024px or less */
@media only screen and (max-width: 1024px) {
    .footer-container {
        padding-left: 10%;
        font-size: 1.8em;
    }

    .footer-column-2 {
        margin-left: 10%;
    }

    #fbIcon,
    #igIcon {
        width: 50px;
        height: 50px;
    }
}

/* Devices with a width of 768px or less */
@media only screen and (max-width: 768px) {
    .footer-container {
        flex-direction: column; /* Stack columns vertically */
        align-items: flex-start; /* Align columns to the left */
        padding-left: 5%;
        font-size: 1.5em;
    }

    .footer-column-1,
    .footer-column-2 {
        margin-left: 0;
        width: 100%;
        margin-bottom: 20px; /* Add space between columns */
    }

    .footer-column-2 ol {
        justify-content: space-between; /* Spread icons evenly */
    }

    .footer-column-2 ol li {
        margin-right: 0;
        margin-bottom: 0;
    }

    #fbIcon,
    #igIcon {
        width: 45px;
        height: 45px;
    }
}

/* Devices with a width of 480px or less */
@media only screen and (max-width: 480px) {
    .footer-container {
        padding-left: 2%;
        padding-top: 3%;
        padding-bottom: 3%;
        font-size: 1.2em;
    }

    .footer-column-1 h3 {
        margin-top: 2%;
    }

    .opnunarTimi {
        font-size: 1.2em;
        margin-top: 2%;
    }

    .footer-column-2 ol {
        justify-content: space-around; /* Space icons evenly in a row */
    }

    #fbIcon,
    #igIcon {
        width: 40px;
        height: 40px;
    }
}
