/* Slideshow.css */
.slideshow-container {
    width: 100%;
    max-width: 100%;
    height: 800px; /* Explicit height to ensure the container is visible */
    margin: 0 auto;
    position: relative;
    background-color: #002d5c;
    overflow: hidden; /* Ensure images don't overflow outside the container */
  }
  
  .slideshow-image {
    width: 100%;
    height: 100%; /* Ensure the image takes up the full height of the container */
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 1s ease-in-out;
    opacity: 0;
    object-fit: cover; /* Ensures the image covers the container without distortion */
  }
  
  .slideshow-image.active {
    opacity: 1;
  }
  
  .prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: background-color 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
  }
  
  .prev {
    left: 0;
    border-radius: 3px 0 0 3px;
  }
  
  .next {
    right: 0;
    border-radius: 0 3px 3px 0;
  }
  
  .prev:hover, .next:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  