@import url(../../assets/variables.css);
@import url(../../assets/fonts.css);

.coverphoto-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100vh; 
  width: 100%; 
  background-image: url('../../assets/a3d254c5ce49712e808f194cd94c2df0d41ba9be\ \(3\).jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.body-content {
  display: flex;
  font-family: var(--font-oswald-regular);
  flex-direction: column;
  margin: 8%;
}

.body-button {
  background-color: red;
  color: white;
  font-size: 18px;
  font-family: var(--font-oswald-bold);
  line-height: 30px;
  padding: 15px 30px;
  border-style: none;
}

.body-texti {
  color: #002d5c;
  font-size: 3em;
}

.nedri-body-texti {
  box-sizing: border-box;
  margin-left: 10%;
  width: 800px;
}

/* Media Queries */

/* Devices with a width of 1024px or less */
@media only screen and (max-width: 1024px) {
  .nedri-body-texti {
    width: 600px;
    margin-left: 5%;
  }

  .body-texti {
    font-size: 2.5em;
  }

  .body-content {
    margin: 5%;
  }

  .body-button {
    font-size: 16px;
    padding: 12px 24px;
  }
}

/* Devices with a width of 768px or less */
@media only screen and (max-width: 768px) {
  .coverphoto-container {
    justify-content: center;
    align-items: flex-start;
  }

  .nedri-body-texti {
    width: 100%;
    margin-left: 0;
    padding: 0 10px;
  }

  .body-texti {
    font-size: 2em;
  }

  .body-content {
    margin: 3%;
  }

  .body-button {
    font-size: 14px;
    padding: 10px 20px;
  }
}

/* Devices with a width of 480px or less */
@media only screen and (max-width: 480px) {
  .coverphoto-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    padding: 20px 0;
  }

  .nedri-body-texti {
    width: 100%;
    margin-left: 0;
    padding: 0 20px;
  }

  .body-texti {
    font-size: 1.5em;
  }

  .body-content {
    margin: 2%;
  }

  .body-button {
    font-size: 12px;
    padding: 8px 16px;
  }
}
