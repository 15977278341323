@font-face {
    font-family: 'Oswald';
    src: url('../assets/Oswald/Oswald-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Oswald';
    src: url('../assets/Oswald/Oswald-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Oswald';
    src: url('../assets/Oswald/Oswald-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Oswald';
    src: url('../assets/Oswald/Oswald-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Oswald';
    src: url('../assets/Oswald/Oswald-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Oswald';
    src: url('../assets/Oswald/Oswald-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}