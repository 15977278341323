.pageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.umOkkurContainer1,
.umOkkurContainer2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin-bottom: 40px;
}

.umOkkurContainer1 .textContainer,
.umOkkurContainer2 .textContainer {
    flex: 1;
    padding: 0 20px;
}

.umOkkurContainer1 .imageContainer,
.umOkkurContainer2 img {
    flex: 1;
    display: flex;
    justify-content: center;
}

.umOkkurContainer1 .imageContainer img,
.umOkkurContainer2 img {
    width: 100%;
    max-width: 400px;
    height: auto;
    object-fit: cover;
    aspect-ratio: 3 / 4; /* This creates a portrait aspect ratio */
    border-radius: 2px;
}

.umOkkurContainer1 .imageContainer img {
    margin-left: 100px; /* Add a margin to push the upper image right */
}



.umOkkurContainer1 {
    flex-direction: row;
}

.umOkkurContainer2 {
    flex-direction: row-reverse;
}

.textContainer p {
    text-transform: none; /* Reset uppercase for paragraphs */
    color: #002d5c; /* Set paragraph color */
    font-size: 1.5rem;           /* Slightly smaller font size */
    line-height: 1.6;          /* Increased line-height for better readability */
    font-weight: 400;          /* Make paragraph text lighter */
    margin-bottom: 20px;
}

h2 {
    color: #002d5c; /* Set header color */
    font-size: 3em  ;
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .umOkkurContainer1,
    .umOkkurContainer2 {
        flex-direction: column;
    }

    .umOkkurContainer1 .imageContainer,
    .umOkkurContainer2 .imageContainer {
        margin-bottom: 20px;
    }
    .umOkkurContainer1 .imageContainer img {
        margin-left: 0px; /* Add a margin to push the upper image right */
    }
}
